import React from "react";

import "./default-layout.scss";

export const DefaultLayout = (props) => {
    return (
        <div className={props.className}>
            <div className="c-default-background">
                <div className="c-default-background-layout">
                    <div className="c-default-background-layout__left"></div>
                    <div className="c-default-background-layout__main"></div>
                    <div className="c-default-background-layout__right"></div>
                </div>
            </div>
            <main>
                {props.children}
            </main>
        </div>
    );
};

export default DefaultLayout;
