import React from "react";

import "./content-layout.scss";

export const ContentLayout = (props) => {

    const containerClassName = props.className ? `${props.className} c-content-layout` : "c-content-layout";

    return (
        <div className={containerClassName}>
            <div className="g-grid__container--content-layout">
                <div className="g-grid__item--content-pl"></div>
                <div className="g-grid__item--content-main">
                    {props.children}
                </div>
                <div className="g-grid__item--content-pr"></div>
            </div>
        </div>
    );
};

export default ContentLayout;
